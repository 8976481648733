import React from 'react';
import Header from './Header';
import { Link } from 'react-router-dom';
// import SectionIntegrations from './SectionIntegrations';
// import SectionOrganization from './SectionOrganization';
// import SectionMoreFeatures from './SectionMoreFeatures';
// import SectionPlans from './SectionPlans';
// import SectionDownload from './SectionDownload';
// import SectionInvitation from './SectionInvitation';
// import UserCases from '../../components/UserCases';
// import MarketingEventEntrance from './MarketingEventEntrance';
// import { modaoEntrance } from '../../analytics/events';
import "./index.less"
export default function IndexPage({ className }) {
  // const showMarketingEventEntrance = false;
  return (
    <div style={{height:'100%',position:'relative'}}>
      {/* {showMarketingEventEntrance &&
        <MarketingEventEntrance gaEvent={modaoEntrance} />} */}
      <Header />
      {/* <SectionIntegrations />
      <SectionOrganization />
      <SectionMoreFeatures />
      <SectionPlans />
      <SectionDownload />
      <SectionInvitation /> */}
      {/* <UserCases /> */}
      <div className='Footer'>
       <div className='Footer-RecordNumber'>
       备案号：<a href="https://beian.miit.gov.cn/"  className='Footer_a' target="_blank">粤ICP备14042253号-2</a>
              <div>@2022-2024 深圳小桔熊科技有限公司</div>
              <Link to="/about" className="no-underline">关于我们</Link>
       </div>
    </div>
    </div>
  );
}
