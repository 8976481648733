// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Footer {
  padding: 34px 55px;
  justify-content: center !important;
  background: #1F1F2A;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
}
.Footer .Footer-RecordNumber {
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-family: 'PingFang SC', 'Helvetica Neue', Helvetica, 'Hiragino Sans GB', 'Microsoft YaHei', Arial, sans-serif;
  color: #fff;
  margin: 0 auto;
  box-sizing: border-box;
  line-height: 2;
}
.Footer .Footer-RecordNumber .Footer_a {
  text-decoration: none;
  color: #fff;
  margin-right: 20px;
  cursor: pointer;
}
.Footer .Footer-RecordNumber .no-underline {
  text-decoration: none;
  color: #fff;
  margin-left: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/views/mainPage/index.less"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,kCAAA;EACA,mBAAA;EACA,eAAA;EACA,OAAA;EACA,SAAA;EACA,QAAA;AACJ;AARA;EASQ,aAAA;EACA,uBAAA;EACA,eAAA;EACA,iHAAA;EAEA,WAAA;EACA,cAAA;EACA,sBAAA;EACA,cAAA;AACR;AAlBA;EAoBY,qBAAA;EAEA,WAAA;EAEA,kBAAA;EACA,eAAA;AADZ;AAxBA;EA4BY,qBAAA;EACA,WAAA;EACA,iBAAA;AADZ","sourcesContent":[".Footer{\n    padding: 34px 55px;\n    justify-content: center !important;\n    background: #1F1F2A;\n    position: fixed;\n    left: 0;\n    bottom: 0;\n    right: 0;\n    .Footer-RecordNumber{\n        display: flex;\n        justify-content: center;\n        font-size: 14px;\n        font-family: 'PingFang SC', 'Helvetica Neue', Helvetica, 'Hiragino Sans GB', 'Microsoft YaHei', Arial, sans-serif;\n        // color: #545763; \n        color: #fff;\n        margin: 0 auto;\n        box-sizing: border-box;\n        line-height: 2;\n        // text-align: center;\n        .Footer_a{\n            text-decoration:none;\n            // color: #545763;\n            color:#fff;\n            cursor: pointer; \n            margin-right: 20px;\n            cursor: pointer; \n        }\n        .no-underline{\n            text-decoration: none;\n            color:#fff;\n            margin-left: 20px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
