import React from 'react';
import { Routes,Route } from 'react-router-dom';
import IndexPage from '../mainPage';
import AboutPage from '../about';

// import './index.less';

const App = () => {
    return (
            <Routes>
                <Route path="/" element={<IndexPage />} />
                {/* <Route path=":id" element={<AboutPage />} /> */}
                <Route path="/about" element={<AboutPage />} />
            </Routes>
    );
};

export default App;
