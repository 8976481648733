import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.less';
import App from '@/views/app';
import { BrowserRouter } from 'react-router-dom';
const Root = () => {
    return (
        <BrowserRouter>
            <App />
        </BrowserRouter>
    );
};

ReactDOM.render(<Root />, document.getElementById('root'));
