// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  height: 100vh;
}
`, "",{"version":3,"sources":["webpack://./src/styles/index.less"],"names":[],"mappings":"AAAA;EACE,SAAA;EACA,aAAA;AACF","sourcesContent":["body {\n  margin: 0;\n  height: 100vh;\n  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n  //   sans-serif;\n  // -webkit-font-smoothing: antialiased;\n  // -moz-osx-font-smoothing: grayscale;\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
