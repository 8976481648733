import React from 'react';
import compose from 'recompose/compose';
import beiqia from './images/beiqia.jpg';
import Headerlogo from '../mainPage/images/logo.png'
import { withHandlers } from 'recompose'
import { setDisplayName } from 'recompose'
import { Link } from 'react-router-dom';

import './index.less';

function AboutConent({handeleclik}) {
  return (
    <div style={{marginBottom:'100px'}}>
    <div className='Header'>
      <Link className='Header-logo'  to='/'>
      <img className='Headerlogo' src={Headerlogo} alt="" />
      </Link>
    </div>
    <div  className='container_about'>
      <div className="content">
        <div className="bannerMain">
          <div style={{display:'flex',marginBottom:'25px'}}>
            <div><img className='img_size' src={beiqia}></img></div>
            <div  style={{display:'block',height:'200px',verticalAlign:'middle'}}>
              <h3>倍洽</h3>
              <p style={{color:'#545763',fontSize:'14px'}}>是我们在2015年推出的一款面向企业和团队的沟通工具，即时沟通为基础，通过 「第三方服务集成」、「文件共享」、「自定义机器人」等方式将用户关心的信息实时汇聚起来，再通过信息的「永久保存」、
                「一键收藏」、「全局搜索」、「实时预览」帮用户将信息流快速梳理，从而提高工作效率。
              </p>
            </div>
          </div>
        </div>
        <div className="bannerMain">
          <div style={{display:'flex'}}>
            <div><img className='img_size' src={beiqia}></img></div>
            <div  style={{display:'block',height:'200px',verticalAlign:'middle'}}>
              <h3>深圳小桔熊科技有限公司</h3>
              <p style={{color:'#545763',fontSize:'14px'}}>总部（深圳）:深圳市福田区沙头街道天安社区深南大道6023号耀华创建大厦9061
              </p>
              <p style={{color:'#545763',fontSize:'14px'}}>邮箱: info@bearyinnovative.com
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className='Footer'>
       <div className='Footer-RecordNumber'>
       备案号：<a href="https://beian.miit.gov.cn/"  className='Footer_a' target="_blank">粤ICP备14042253号-2</a>
       <div>@2022-2024 深圳小桔熊科技有限公司</div>
       <div  className="no-underline" onClick={handeleclik}>关于我们</div>
       </div>
    </div>
    </div>
  );
}

export default compose(
  setDisplayName('AboutConent'),
  withHandlers({
    handeleclik: ({ }) => () => window.location.reload(),
  })
)(AboutConent);
