// import React from 'react';
import React,{ useState } from 'react';
import compose from 'recompose/compose';
import homeBanner from '../images/home-banner.svg';
import fakeChatUIHeader from '../images/fake-chat-UI-header.png';
import fakeChatUIInbox from '../images/fake-chat-UI-inbox.png';
import fakeChatUIStream from '../images/fake-chat-UI-stream.mp4';
import fakeChatUIStaticStream from '../images/fake-chat-UI-stream.png';
import Headerlogo from '../images/logo.png'
import { withState } from 'recompose'
import { withHandlers } from 'recompose'
import { setDisplayName } from 'recompose'

import './style.less';
function HomeHeader({
  videoLoaded,
  onVideoLoaded,
  handClik
}) {
  return (
    <div style={{marginBottom:'100px'}}>
    <div className='Header' >
      <div className='Header-logo' onClick={handClik}>
      <img className='Headerlogo' src={Headerlogo} alt="" />
      </div>
    </div>
    <div  className='container'>
      <div className="banner">
        <div className="bannerMain">
          <h1  className='name'>倍洽，</h1>
          <p  className='slogan'>面向工作场景的</p>
          <p  className='slogan'>智能化消息处理中心</p>
        </div>
        <img  src={homeBanner} alt="" />
      </div>
      <div className='videoBoard'>
        <div className='fakeChatInboxWrapper'>
          <img className='fakeChatInbox' src={fakeChatUIInbox} alt="" />
        </div>
        <div className='videoSubBoard'>
          <img
            className='fakeChatHeader'
            src={fakeChatUIHeader}
            alt=""
          />
          {videoLoaded ||
            <img
              className='fakeChatHeader'
              src={fakeChatUIStaticStream}
              alt=""
            />}
          <video
            className='video'
            style={videoLoaded ? {} : { display: 'none' }}
            loop
            autoPlay
            muted
            onLoadedData={onVideoLoaded}
            src={fakeChatUIStream}
          />
        </div>
      </div>
    </div>
    </div>
  );
}

export default compose(
  setDisplayName('HomeHeader'),
  withState('videoLoaded', 'setVideoLoaded', false),
  withHandlers({
    onVideoLoaded: ({ setVideoLoaded }) => () => setVideoLoaded(true),
    handClik: ({ }) => () => window.location.reload(),
  })
)(HomeHeader);
